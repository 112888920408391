import { firstValueFrom } from 'rxjs'

import { getTreatmentIsEnabled } from '@wf-mfe/toggles'

export const notifyViewerExtension = async (
  addVersion,
  settingsLink,
  breadcrumbs,
) => {
  const hideCloseButtonToggle = await firstValueFrom(
    getTreatmentIsEnabled('proof-viewer-opens-tab'),
  )
  const { contentWindow } = document.getElementById('proof-viewer-iframe')
  const message = {
    type: 'QSdata',
    data: {
      quicksilver: true,
      breadcrumbs,
      permissions: { addVersion },
      toggles: {
        'proof-viewer-opens-tab': hideCloseButtonToggle,
      },
      settingsLink,
    },
  }

  contentWindow.postMessage(message, '*')
}
