import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { useCurrentUser } from '@wf-mfe/auth'
import { Page, PageContent } from '@wf-mfe/layout'

import ProofComponent from './ProofComponent.js'

// react-router-dom uses "Compoonent" for lazy renders
export function Component() {
  const currentUser = useCurrentUser()
  const params = useParams()
  const navigate = useNavigate()

  return (
    <Page hasToolbar={false} mfeName="@wf-mfe/proof-viewer-ui">
      <PageContent fullBleed>
        <ProofComponent
          params={params}
          navigate={navigate}
          currentUser={currentUser}
        />
      </PageContent>
    </Page>
  )
}
