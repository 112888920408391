import React, { Component } from 'react'
import { css } from 'emotion'
import { wfetch, FORM_CONTENT_TYPE_HEADER } from '@wf-mfe/api'
import { Document } from 'workfront-objcodes'
import { getObjectLink } from '@wf-mfe/navigation'
import { notifyViewerExtension } from './extensions/notifyViewerExtension'

const iframeCss = () => {
  const navigationHeight = document
    .querySelector('#navigation')
    .getBoundingClientRect().height

  return css`
    width: 100%;
    min-height: calc(100vh - ${navigationHeight}px);
  `
}

const DOCUMENT_PERMISSION_EDIT = 'EDIT'

class ProofComponent extends Component {
  state = {
    breadcrumbs: [],
    versions: [{}],
  }

  componentDidMount() {
    this.getProofUrls()
    const documentId = this.props.params.ID
    const compareDocumentId = this.props.params.CompareID

    if (!compareDocumentId) {
      this.getBreadCrumbs([documentId])
    } else {
      this.getBreadCrumbs([documentId, compareDocumentId])
    }

    this.addMessageHandler()
  }

  componentWillUnmount() {
    this.clearMessageHandlers()
  }

  addMessageHandler() {
    window.addEventListener('message', this.onMessage, false)
  }

  clearMessageHandlers() {
    window.removeEventListener('message', this.onMessage)
  }

  goToNewVersion = () => {
    const objID = this.props.params.ID
    const parentObj = this.getParentObject()
    const newVersionUrl = `/document/proof/create/documentID=${objID}&objCode=${parentObj.objCode}&objID=${parentObj.objID}&redirectToViewer=true`
    this.props.navigate(newVersionUrl)
  }

  getParentObject = () => {
    let breadcrumbs = this.state.breadcrumbs

    if (breadcrumbs.length === 0) {
      return null
    }
    breadcrumbs = breadcrumbs[0]
    if (breadcrumbs.length < 1) {
      return null
    }
    return breadcrumbs[breadcrumbs.length - 1]
  }

  closeViewer = () => {
    const nextNavigatePath = '/documents'

    const urlParams = new URLSearchParams(this.props.location.search)
    const forceReload = urlParams.has('shell_forceReload')
    const navigateBack = 1
    const unifiedShellRedirectsBack = 2
    const navigateMinLength = forceReload
      ? unifiedShellRedirectsBack
      : navigateBack

    const hasHistory = !!document.referrer
    const comesFromLoginPage =
      document.referrer.includes('adobeid') ||
      document.referrer.includes('login')

    if (hasHistory && !comesFromLoginPage) {
      this.props.navigate(-navigateMinLength)
      return
    }

    this.props.navigate(nextNavigatePath)
  }

  onMessage = (event) => {
    if (event.data.letznavID) return //ignoring apty messages

    if (event.data === 'htmlViewer-loaded') {
      this.setState({ viewerLoaded: true }, this.notifyViewer)
    }
    const { source, href } = event.data

    if (source === 'addNewVersion') {
      this.goToNewVersion(event.data.proofID)
      return
    }

    if (source === 'closeViewer') {
      this.closeViewer()
      return
    }

    if (source === 'qsnav') {
      this.props.navigate(href)
    }

    if (source === 'open-proof-mobile') {
      document.location.href = href
    }
  }

  getDocumentDetails(documentID) {
    return wfetch(
      `/attask/api-internal/${Document}/${documentID}?fields=${[
        'ID',
        'name',
        'permissions',
        'checkedOutByID',
        'versions:ID',
        'versions:proofID',
        'folders:linkedFolderID',
      ].join(',')}`,
    )
  }

  getSingleProofIframeURL(proofID, commentID = '') {
    return wfetch('/internal/document/proof/url', {
      method: 'POST',
      headers: FORM_CONTENT_TYPE_HEADER,
      body: `proofID=${proofID}&commentID=${commentID}`,
    })
  }

  getBreadCrumbs(documents) {
    const promises = documents.map((documentID) =>
      this.getSingleBreadcrumb(documentID),
    )

    Promise.all(promises).then((breadcrumbs) => {
      this.setState(() => ({
        breadcrumbs: breadcrumbs,
      }))
    })
  }

  getSingleBreadcrumb(documentID) {
    return wfetch(
      `/internal/breadcrumb?objID=${documentID}&objCode=${Document}`,
    ).then((result) => {
      const breadcrumbs = result.map((breadcrumbItem) => {
        return {
          name: breadcrumbItem.name,
          objCode: breadcrumbItem.objCode,
          objID: breadcrumbItem.objID,
          link: getObjectLink({
            objCode: breadcrumbItem.objCode,
            ID: breadcrumbItem.objID,
          }).to,
          origin: document.location.origin,
        }
      })
      if (breadcrumbs.length > 1) {
        breadcrumbs.pop()
      }
      return breadcrumbs
    })
  }

  getComparisonProofIframeURL(documentOneID, documentTwoID) {
    return wfetch('/internal/document/proof/comparison', {
      method: 'POST',
      headers: FORM_CONTENT_TYPE_HEADER,
      body: `documentID1=${documentOneID}&documentID2=${documentTwoID}`,
    })
  }

  addUrlParam(url, param) {
    return url.indexOf('?') !== -1 ? `${url}&${param}` : `${url}?${param}`
  }

  addQuicksilverToProofUrl(proofUrl) {
    return this.addUrlParam(proofUrl, 'qs=1')
  }

  getProofUrls = () => {
    if (!this.props.params.CompareID) {
      const { ProofID, CommentID } = this.props.params
      const documentId = this.props.params.ID
      this.getSingleProofIframeURL(ProofID, CommentID).then((response) => {
        if (response.error) {
          //redirect to doc details if we do not get a proof url back
          this.props.navigate(`/document/${documentId}`, { replace: true })
        } else {
          this.setState(() => ({
            iframeUrl: this.addQuicksilverToProofUrl(response),
          }))
        }
      })

      this.getDocumentDetails(documentId).then(
        (documentDetailsResponse = {}) => {
          const { versions, permissions, folders, checkedOutByID } =
            documentDetailsResponse
          this.setState({ versions, permissions, folders, checkedOutByID })
        },
      )
    } else {
      const documentOneID = this.props.params.ID
      const documentTwoID = this.props.params.CompareID
      this.getComparisonProofIframeURL(documentOneID, documentTwoID).then(
        (response) => {
          this.setState(() => ({
            iframeUrl: this.addQuicksilverToProofUrl(response),
          }))
        },
      )
    }
  }

  getVersionFromProofID() {
    const { ProofID } = this.props.params
    const version = this.state.versions.find((v) => v.proofID === ProofID)

    return !!version && version.ID
  }

  getSettingsLink() {
    const objID = this.props.params.ID
    const versionID = this.getVersionFromProofID()

    return `${window.location.origin}/document/${objID}/${versionID}/proofing-workflow`
  }

  notifyViewer = () => {
    if (!this.state.viewerLoaded) {
      return
    }

    notifyViewerExtension(
      this.canAddNewVersion(),
      this.getSettingsLink(),
      this.state.breadcrumbs,
    )
  }

  canAddNewVersion = () => {
    if (!this.state.permissions) {
      return false
    }
    const { actions } = this.state.permissions
    return (
      actions &&
      actions.indexOf(DOCUMENT_PERMISSION_EDIT) !== -1 &&
      !this.state.checkedOutByID &&
      !this.isDocumentInLinkedFolder()
    )
  }

  isDocumentInLinkedFolder = () => {
    const { folders } = this.state
    return (
      folders.filter((folder) => folder.linkedFolderID !== null).length !== 0
    )
  }

  render() {
    const { iframeUrl } = this.state

    if (!iframeUrl) {
      return null
    }

    return (
      <iframe
        id="proof-viewer-iframe"
        data-testid="proof-viewer-iframe"
        title="proof-viewer-iframe"
        src={this.addUrlParam(iframeUrl, 'skipQssRedirect=true')}
        className={iframeCss()}
      />
    )
  }
}

export default ProofComponent
